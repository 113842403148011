export const services = [
  {
    title_en: "Haircut & beard",
    title_fi: "Hiustenleikkaus ja parran muotoilu",
    price: "39€",
    description_en:
      "Get a stylish haircut with a groomed beard for a special occasion or an everyday look.",
    description_fi:
      "Hanki tyylikäs leikkaus ja muotoilu erikoistilaisuuksiin tai arkityyliäsi piristämään.",
  },
  {
    title_en: "Haircut",
    title_fi: "Hiustenleikkaus",
    price: "20€",
    description_en: "Get a stylish haircut tailored to your preferences.",
    description_fi:
      "Meiltä saat toiveidesi mukaisen hiustenleikkauksen sinulle räätälöitynä.",
  },
  {
    title_en: "Beard trim with razor",
    title_fi: "Parranajo veitsellä",
    price: "20€",
    description_en: "Achieve a neat and well-groomed beard look.",
    description_fi: "Siisti ja huoliteltu ilme kädenkäänteessä.",
  },
  {
    title_en: "Hair coloring",
    title_fi: "Hiusten värjäys",
    price: "30€",
    description_en: "Nothing like a fresh new hair color to boost your mood.",
    description_fi: "Hyvä väri, parempi mieli.",
  },
  {
    title_en: "Hair washing",
    title_fi: "Hiustenpesu",
    price: "5€",
    description_en: "Get the perfect wash that cares for your strands.",
    description_fi: "Helli hiuksiasi hiustenpesullamme.",
  },
  {
    title_en: "Eyebrows trim",
    title_fi: "Kulmakarvojen trimmaus",
    price: "10€",
    description_en: "Get your favorite eyebrows style.",
    description_fi: " Huolittele tyylisi siistimällä kulmakarvasi meillä.",
  },
];
