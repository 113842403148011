import React, { useState } from "react";
import "./Navbar.css";
import { GiHamburgerMenu } from "react-icons/gi";
import { MdClose } from "react-icons/md";
import images from "../../constants/images";
import { useTranslation } from "react-i18next";

const Navbar = () => {
  const [toggleMenu, setToggleMenu] = useState(false);
  const { t } = useTranslation();

  const scrollToSection = (sectionId) => {
    const section = document.getElementById(sectionId);
    if (section) {
      const topOffset = section.offsetTop;
      window.scrollTo(0, topOffset);
      setToggleMenu(false);
    }
  };

  return (
    <nav className="app__navbar">
      <div className="app__navbar-logo">
        <img src={images.logoT} alt="app-logo" />
      </div>
      <ul className="app__navbar-links">
        <li className="p__opensans">
          <a href="#home" onClick={() => scrollToSection("home")}>
            {t("navbar.home")}
          </a>
        </li>
        <li className="p__opensans">
          <a href="#Services" onClick={() => scrollToSection("Services")}>
            {t("navbar.services")}
          </a>
        </li>{" "}
        <li className="p__opensans">
          <a href="#Barbers" onClick={() => scrollToSection("Barbers")}>
            {t("navbar.barbers")}
          </a>
        </li>{" "}
        <li className="p__opensans">
          <a href="#gallery" onClick={() => scrollToSection("Gallery")}>
            {t("navbar.gallery")}
          </a>
        </li>
        <li className="p__opensans">
          <a href="#AboutUs" onClick={() => scrollToSection("AboutUs")}>
            {t("navbar.About")}
          </a>
        </li>
        <li className="p__opensans">
          <a
            href="#Contact-form"
            onClick={() => scrollToSection("Contact-form")}
          >
            {t("navbar.Contact")}
          </a>
        </li>
      </ul>
      <div className="app__navbar-smallscreen">
        <GiHamburgerMenu
          color="#fff"
          fontSize={27}
          onClick={() => setToggleMenu(true)}
        />
        {toggleMenu && (
          <div className="app__navbar-smallscreen_overlay flex__center slide-bottom">
            <MdClose
              fontSize={27}
              className="overlay__close"
              onClick={() => setToggleMenu(false)}
            />

            <ul className="app__navbar-smallscreen_links">
              <li className="p__opensans">
                <a href="#Home" onClick={() => scrollToSection("Home")}>
                  {t("navbar.home")}
                </a>
              </li>
              <li className="p__opensans">
                <a href="#Services" onClick={() => scrollToSection("Services")}>
                  {t("navbar.services")}
                </a>
              </li>{" "}
              <li className="p__opensans">
                <a href="#Barbers" onClick={() => scrollToSection("Barbers")}>
                  {t("navbar.barbers")}
                </a>
              </li>{" "}
              <li className="p__opensans">
                <a href="#Gallery" onClick={() => scrollToSection("Gallery")}>
                  {t("navbar.gallery")}
                </a>
              </li>
              <li className="p__opensans">
                <a href="#AboutUs" onClick={() => scrollToSection("AboutUs")}>
                  {t("navbar.About")}
                </a>
              </li>
              <li className="p__opensans">
                <a
                  href="#Contact-form"
                  onClick={() => scrollToSection("Contact-form")}
                >
                  {t("navbar.Contact")}
                </a>
              </li>
            </ul>
          </div>
        )}
      </div>
    </nav>
  );
};

export default Navbar;
