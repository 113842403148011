import "./App.css";
import Navbar from "./components/Navbar/Navbar";
import Services from "./components/Services/Services";
import Barbers from "./components/Barbers/Barbers";
import Gallery from "./components/Gallery/Gallery";
import Footer from "./components/Footer/Footer";
import Home from "./components/Home/Home";
import AboutUs from "./components/About us/AboutUs";
import "./scroll-animation";
import MapComponent from "./components/Contact/Contact";
import ScrollTop from "./scrollTo-top";
import ContactUs from "./components/contact-form/Contact-form";
const App = () => {
  return (
    <div className="app">
      <Navbar />
      <Home />
      <Services />
      <Barbers />
      <Gallery />
      <AboutUs />
      <ContactUs />
      <MapComponent center={{ lat: 61.4656634, lng: 24.0473171 }} zoom={13} />
      <Footer />
      <ScrollTop />
    </div>
  );
};

export default App;
