import React, { useRef } from "react";
import emailjs from "@emailjs/browser";
import "./Contact-form.css";
import { useTranslation } from "react-i18next";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export default function ContactUs() {
  const { t } = useTranslation();

  const form = useRef();
  //popup message
  const notify = () => {
    toast.success("Message sent 👍!", {
      position: "top-center",
      autoClose: 3000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });
  };
  const sendEmail = (e) => {
    e.preventDefault();
    //emailjs config id, service

    emailjs
      .sendForm(
        "service_erbvvv9",
        "template_4b3hc4e",
        form.current,
        "5XEz5M9vZxWNNjqbt"
      )
      .then(
        (result) => {
          console.log(result.text);
          notify();

          //refresh contact form after submit
          form.current.reset();
        },
        (error) => {
          console.log(error.text);
        }
      );
  };

  return (
    <div className="container" id="Contact-form">
      <h1 className="tag"> {t("Contact us")}</h1>
      <div className="contact">
        <div>
          <div className="container_h">
            <form
              className="form tag"
              id="contact"
              ref={form}
              onSubmit={sendEmail}
            >
              <input
                placeholder={t("Your name...")}
                name="user_name"
                type="text"
                required
              />
              <input
                placeholder={t("Your Email Address...")}
                type="email"
                name="user_email"
                required
              />
              <textarea
                placeholder={t("Type your message here....")}
                name="message"
                required
              ></textarea>
              <button type="submit">{t("Send email")}</button>
            </form>
          </div>
          <ToastContainer />
        </div>
      </div>
    </div>
  );
}
