import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import "./LanguageSwitcher.css";

const LanguageSwitcher = () => {
  const { i18n } = useTranslation();
  const value = ["fi", "en"];
  const [active, setActive] = useState(value[0]);
  const handleChangeLanguage = (event) => {
    const selectedLanguage = event.target.value;
    setActive(!active);
    i18n.changeLanguage(selectedLanguage);
  };

  return (
    <div className="language-switcher">
      <button
        className="l-button"
        value={active ? value[1] : value[0]}
        onClick={handleChangeLanguage}
      >
        {active ? "EN" : "FI"}
      </button>
    </div>
  );
};

export default LanguageSwitcher;
