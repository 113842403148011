// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../assets/location.jpg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.map-container {
  background-color: var(--color-black);
}

.container-main h1 {
  color: var(--color-golden);
  background: rgb(12, 12, 12, 0.7);
  padding: 10px;
  border-radius: 10px;
}
.location {
  height: 200px;
  background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px 10px;
}
.header {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;

}

.location .Type-writer {
  color: var(--color-golden);
  border-right: unset;
  animation: arrow1 0.5s steps(2) alternate infinite;
  text-align: center;
}
@keyframes arrow1 {
  from {
    width: 0%;
  }
  to {
    width: 40%;
  }
}
@media screen and (max-width: 700px) {

  .location{
    background-attachment: unset;
  }
}
@media screen and (max-width: 500px) {
  .container-main h1 {
    text-align: center;
    font-size: 22px;
  }
  .location{
    background-attachment: unset;
  }
}
@media screen and (min-width: 2000px) {
  .container-main h1 {
    font-size: 40px;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/Contact/Contact.css"],"names":[],"mappings":"AAAA;EACE,oCAAoC;AACtC;;AAEA;EACE,0BAA0B;EAC1B,gCAAgC;EAChC,aAAa;EACb,mBAAmB;AACrB;AACA;EACE,aAAa;EACb,yDAAkD;EAClD,2BAA2B;EAC3B,sBAAsB;EACtB,4BAA4B;EAC5B,4BAA4B;EAC5B,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,wBAAwB;AAC1B;AACA;EACE,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,SAAS;;AAEX;;AAEA;EACE,0BAA0B;EAC1B,mBAAmB;EACnB,kDAAkD;EAClD,kBAAkB;AACpB;AACA;EACE;IACE,SAAS;EACX;EACA;IACE,UAAU;EACZ;AACF;AACA;;EAEE;IACE,4BAA4B;EAC9B;AACF;AACA;EACE;IACE,kBAAkB;IAClB,eAAe;EACjB;EACA;IACE,4BAA4B;EAC9B;AACF;AACA;EACE;IACE,eAAe;EACjB;AACF","sourcesContent":[".map-container {\n  background-color: var(--color-black);\n}\n\n.container-main h1 {\n  color: var(--color-golden);\n  background: rgb(12, 12, 12, 0.7);\n  padding: 10px;\n  border-radius: 10px;\n}\n.location {\n  height: 200px;\n  background-image: url(\"../../assets/location.jpg\");\n  background-position: center;\n  background-size: cover;\n  background-repeat: no-repeat;\n  background-attachment: fixed;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  border-radius: 10px 10px;\n}\n.header {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  gap: 10px;\n\n}\n\n.location .Type-writer {\n  color: var(--color-golden);\n  border-right: unset;\n  animation: arrow1 0.5s steps(2) alternate infinite;\n  text-align: center;\n}\n@keyframes arrow1 {\n  from {\n    width: 0%;\n  }\n  to {\n    width: 40%;\n  }\n}\n@media screen and (max-width: 700px) {\n\n  .location{\n    background-attachment: unset;\n  }\n}\n@media screen and (max-width: 500px) {\n  .container-main h1 {\n    text-align: center;\n    font-size: 22px;\n  }\n  .location{\n    background-attachment: unset;\n  }\n}\n@media screen and (min-width: 2000px) {\n  .container-main h1 {\n    font-size: 40px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
