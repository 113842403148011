// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.language-switcher {
  display: flex;
  justify-content: flex-end;
  color: var(--color-golden);
  padding: 4px;
  border: none;
}

.l-button {
  font-size: 0.8rem;
  border-radius: 1px;
  background-color: var(--color-gray);
  color: var(--color-golden);
  border: 1px solid var(--color-golden);
  padding: 5px 5px;
  cursor: pointer;
  appearance: none;
  opacity: 0.8;
  width: 35px;
}
@media screen and (min-width: 2000px) {
  .l-button {
    font-size: 30px;
    width: 100px;

 
}
}`, "",{"version":3,"sources":["webpack://./src/components/Language/LanguageSwitcher.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,yBAAyB;EACzB,0BAA0B;EAC1B,YAAY;EACZ,YAAY;AACd;;AAEA;EACE,iBAAiB;EACjB,kBAAkB;EAClB,mCAAmC;EACnC,0BAA0B;EAC1B,qCAAqC;EACrC,gBAAgB;EAChB,eAAe;EACf,gBAAgB;EAChB,YAAY;EACZ,WAAW;AACb;AACA;EACE;IACE,eAAe;IACf,YAAY;;;AAGhB;AACA","sourcesContent":[".language-switcher {\n  display: flex;\n  justify-content: flex-end;\n  color: var(--color-golden);\n  padding: 4px;\n  border: none;\n}\n\n.l-button {\n  font-size: 0.8rem;\n  border-radius: 1px;\n  background-color: var(--color-gray);\n  color: var(--color-golden);\n  border: 1px solid var(--color-golden);\n  padding: 5px 5px;\n  cursor: pointer;\n  appearance: none;\n  opacity: 0.8;\n  width: 35px;\n}\n@media screen and (min-width: 2000px) {\n  .l-button {\n    font-size: 30px;\n    width: 100px;\n\n \n}\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
