import React from "react";
import ReactDOM from "react-dom/client";
import { I18nextProvider } from "react-i18next";
import i18n from "i18next";
import "./index.css";
import enTranslations from "./locales/en.json";
import fiTranslations from "./locales/fi.json";
import App from "./App";
import { BrowserRouter as Router } from "react-router-dom";

i18n.init({
  interpolation: { escapeValue: false },
  lng: "fi", // Default language
  resources: {
    en: {
      translation: enTranslations,
    },
    fi: {
      translation: fiTranslations,
    },
  },
});

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Router>
    <I18nextProvider i18n={i18n}>
      <App />
    </I18nextProvider>
  </Router>
);
