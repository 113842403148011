import React from "react";
import "./Barbers.css";
import { barbers } from "../../constants/barbers.js";
import { useTranslation } from "react-i18next";
import images from "../../constants/images";

const Barbers = () => {
  const { t } = useTranslation();

  return (
    <div className="section__padding main-container" id="Barbers">
      <h1 className=" tag">{t("navbar.barbers")}</h1>
      <div className="barbers-container ">
        {barbers.map((barber, index) => (
          <div className="barbers " key={index}>
            <div className="barber-info tag ">
              <h2>{t(barber.name)}</h2>
              <br />
              <p>{t(barber.info)}</p>
              <img src={images.razor} alt="app-logo" />
            </div>
            <div className="barber-img tag">
              <img className="omar-img " src={images.bar} alt="app-logo" />
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Barbers;
