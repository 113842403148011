import React from "react";
import { useTranslation } from "react-i18next";
import "./Services.css";
import { services } from "../../constants/data";

const Services = () => {
  const { t } = useTranslation();

  return (
    <div className="section__padding main-container " id="Services">
      <h1 className=" tag ">{t("navbar.services")}</h1>
      <div className="background-img  ">
        <div className="services-container">
          {services.map((service, index) => (
            <div className="service tag" key={index}>
              <h3>{t(service.title_en)}</h3>
              <p>{t(service.price)}</p>
              <p>{t(service.description_en)}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Services;
