import logoT from "../assets/logoT.png";
import logo from "../assets/logo.png";
import sci from "../assets/sci.png";
import razor from "../assets/razor.png";
import cut1 from "../assets/cuts/cut1.jpeg";
import cut2 from "../assets/cuts/cut2.jpeg";
import cut3 from "../assets/cuts/cut3.jpeg";
import cut4 from "../assets/cuts/cut4.jpeg";
import cut5 from "../assets/cuts/cut5.jpeg";
import cut6 from "../assets/cuts/cut6.jpeg";
import email from "../assets/email.png";
import pro from "../assets/pro.png";
import bar from "../assets/bar.jpg";

const images = {
  logoT,
  logo,
  sci,
  pro,
  razor,
  cut1,
  cut2,
  cut3,
  cut4,
  cut5,
  cut6,
  email,
  bar,
};

export default images;
