import React, { useState } from "react";
import { MdKeyboardDoubleArrowUp } from "react-icons/md";
import "./index.css";
const ScrollTop = () => {
  const [toTop, settoTop] = useState(false);

  const toggleVisible = () => {
    const scrolled = document.documentElement.scrollTop;
    if (scrolled > 800) {
      settoTop(true);
    } else if (scrolled <= 800) {
      settoTop(false);
    }
  };
  const scrollUp = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  window.addEventListener("scroll", toggleVisible);

  return (
    <div className="scroll-main">
      <button
        className="scrollup"
        style={{
          display: toTop ? "inline" : "none",
          position: "fixed",
          right: "2%",
          top: "50%",
          border: "0px",
          opacity: "0.6",
          backgroundColor: "unset",
          color: "var(--color-golden)",
        }}
        onClick={scrollUp}
      >
        <MdKeyboardDoubleArrowUp size={38} />
      </button>
    </div>
  );
};

export default ScrollTop;
