import React, { useState, useEffect } from "react";
import { GoogleMap, LoadScript, Marker } from "@react-google-maps/api";
import "./Contact.css";
import { SlLocationPin } from "react-icons/sl";

const MapComponent = ({ center, zoom }) => {
  const [mapCenter, setMapCenter] = useState(center);
  const [mapZoom, setMapZoom] = useState(zoom);

  const markersData = [
    {
      id: "1",
      latitude: 61.4716083,
      longitude: 24.0080199,
      name: "Omar Parturi",
    },
  ];

  const handleMarkerClick = () => {
    setMapZoom(mapZoom + 1);
  };

  useEffect(() => {
    const adjustMapCenter = () => {
      if (window.innerWidth <= 500) {
        setMapCenter({ lat: 61.4716083, lng: 24.0080199 });
      } else {
        setMapCenter(center);
      }
    };

    adjustMapCenter();

    const handleResize = () => {
      adjustMapCenter();
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [center]);

  return (
    <div className="container-main">
      <div className="section__padding map-container">
        <div className="location">
          <h1 className="header tag">
            <a href="https://www.google.com/maps/place/Omar+Parturi/@61.4716084,24.0103105,17z/data=!3m1!4b1!4m6!3m5!1s0x2f059709135a8c6d:0x84d06102033a08e0!8m2!3d61.4716084!4d24.0128908!16s%2Fg%2F11krbpv2rf?entry=ttu">
              {" "}
              Our location in Kangasala
            </a>
            <span className="Type-writer">
              <SlLocationPin size={30} />
            </span>
          </h1>
        </div>

        <LoadScript
          googleMapsApiKey="AIzaSyByZwUI-67bnNob1U1TgWWqPYkZoaZknjQ"
          onLoad={() => console.log("Google Maps API loaded successfully.")}
        >
          <GoogleMap
            mapContainerStyle={{
              height: "500px",
              width: "100%",
              borderRadius: "0px 0px 10px 10px",
              backgroundColor: "black",
            }}
            center={mapCenter}
            zoom={mapZoom}
          >
            {markersData.map((marker) => (
              <Marker
                key={marker.id}
                position={{ lat: marker.latitude, lng: marker.longitude }}
                onClick={handleMarkerClick}
              />
            ))}
          </GoogleMap>
        </LoadScript>
      </div>
    </div>
  );
};

export default MapComponent;
