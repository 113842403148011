import React from "react";
import { ImScissors } from "react-icons/im";
import images from "../../constants/images";
import "./Home.css";
import { useTranslation } from "react-i18next";
import LanguageSwitcher from "../Language/LanguageSwitcher";

const Home = () => {
  const { t } = useTranslation();

  return (
    <div className="Home-slider" id="Home">
      <LanguageSwitcher />

      <div className="Home-content">
        <h1>
          <ImScissors className="Home-icons" />
          <span>{t("Omar Barbershop")}</span>
          <ImScissors className="Home-icons" />
        </h1>
        <div className="home-logo">
          <img src={images.logoT} alt="app-logo" />
        </div>
        <br />

        <p>{t("More than just a haircut.")}</p>
        <div className="type">
          <p>{t("Come in,")}</p>
          <p className="Type-writer">
            {t("relax and walk out feeling like a new man.")}
          </p>
        </div>
        <br />
        <p>
          <a href="tel:+358405785578">{t("Call & book time")}</a>
        </p>
      </div>
    </div>
  );
};

export default Home;
