import React from "react";
import { useTranslation } from "react-i18next";
import { FaFacebookF, FaInstagram } from "react-icons/fa";
import "./Footer.css";

const Footer = () => {
  const { t } = useTranslation();

  return (
    <footer className="section__padding app__footer">
      <div className="app__footer-container">
        <div className="app__footer-content">
          <h3 className="footer__heading">{t("footer.contact")}</h3>
          <p className="footer__text">
            {t("footer.address")}
            <br />
            {t("footer.phone")}
            <br />
            {t("footer.email")}
          </p>
        </div>
        <div className="app__footer-content">
          <h3 className="footer__heading">{t("footer.openingHours")}</h3>
          <p className="footer__text">
            {t("footer.weekdays")} {t("footer.weekdayHours")}
            <br />
            {t("footer.saturday")} {t("footer.saturdayHours")}
            <br />
            {t("footer.sunday")} {t("footer.sundayHours")}
          </p>
        </div>
        <div className="app__footer-content">
          <h3 className="footer__heading">{t("footer.followUs")}</h3>
          <div className="footer__social-links">
            <a
              href="https://www.facebook.com/profile.php?id=100095215890038"
              className="footer__social-link"
            >
              <FaFacebookF />
            </a>
            <a
              href="https://www.instagram.com/omarparturi_kangasala/?igshid=MzRlODBiNWFlZA%3D%3D"
              className="footer__social-link"
            >
              <FaInstagram />
            </a>
          </div>
        </div>
      </div>
      <div className="app__footer-bottom">
        <p className="footer__text">
          &copy; {new Date().getFullYear()} {t("footer.allRightsReserved")}
        </p>
      </div>
    </footer>
  );
};

export default Footer;
