import React from "react";
import "./Gallery.css";
import image from "../../constants/images";
import { useTranslation } from "react-i18next";

const Gallery = () => {
  const { t } = useTranslation();

  const images = [
    {
      src: image.cut2,
      aspectRatio: "square",
    },
    {
      src: image.cut4,
      aspectRatio: "square",
    },
    {
      src: image.cut5,
      aspectRatio: "square",
    },
    {
      src: image.cut1,
      aspectRatio: "square",
    },
    {
      src: image.cut3,
      aspectRatio: "square",
    },
    {
      src: image.cut6,
      aspectRatio: "square",
    },
  ];
  return (
    <div className="section__padding gallery" id="Gallery">
      {" "}
      <h1 className=" tag">{t("navbar.gallery")}</h1>
      <div className="gallery-container">
        {images.map((image, index) => (
          <div key={index} className={`gallery-image tag ${image.aspectRatio}`}>
            <img src={image.src} alt={`number ${index + 1}`} />
          </div>
        ))}
      </div>
    </div>
  );
};

export default Gallery;
