import "./AboutUs.css";
import React from "react";
import { useTranslation } from "react-i18next";
import ReactPlayer from "react-player";
import video from "../../assets/barber_video.mp4";
import { MdKeyboardDoubleArrowDown } from "react-icons/md";
const AboutUs = () => {
  const { t } = useTranslation();

  return (
    <div className="section__padding main-container" id="AboutUs">
      <h1 className=" tag ">{t("navbar.About")}</h1>
      <div className="aboutus-container">
        <div className="aboutus-video tag">
          <ReactPlayer
            className="video-player"
            url={video}
            width="100%"
            height="100%"
            controls={true}
          />
        </div>
        <div className="aboutus-info">
          <div className="aboutus">
            <h1 className="tittle tag">{t("Why us")}</h1>

            <h3 className="tittle tag Type-writer">
              <span className="icon  ">
                <MdKeyboardDoubleArrowDown className="down " size={40} />
              </span>
            </h3>
          </div>
          <p className="tag">
            {t(
              "The top choice for barbering services in Kangasala. We offer a unique blend of modern and traditional barbering. Our experienced team, premium products, and stylish atmosphere guarantee the best barbering experience. Come and experience the art of barbering at OM Barbershop in Kangasala."
            )}
          </p>
          <br />
        </div>
      </div>
    </div>
  );
};

export default AboutUs;
